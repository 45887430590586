import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import {
  QRCode,
  QRCodeUploadSchema,
  QRCodeUploadSchemaType,
} from "../../schemas/qr_code";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "react-query";
import { UPLOAD } from "../../utils/api";
import { useMessaging } from "../../contexts/message_context";
import { targetDocuments } from "../../utils/utils";
import { ReactNode, useRef } from "react";

export default function Upload({
  show,
  onHide,
  onSuccess,
  handleDownload,
  onClosePreview,
}: {
  show: boolean;
  onHide: () => void;
  onClosePreview: () => void;
  onSuccess: (component: ReactNode) => void;
  handleDownload: (data: QRCode[]) => void;
}) {
  const ref = useRef();
  const client = useQueryClient();
  const { dispatch } = useMessaging();
  const { mutate, isLoading } = useMutation(UPLOAD, {
    onSuccess: async (data) => {
      const { data: res } = data;

      if (res.is_success) {
        const { success, error } = res.data;
        const component = (
          <>
            <DialogContent sx={{ p: 0 }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Successful created</TableCell>
                    <TableCell>
                      <Button
                        disabled={success.length <= 0}
                        onClick={() => {
                          onHide();
                          handleDownload(success);
                        }}
                      >
                        Download
                      </Button>
                    </TableCell>
                  </TableRow>

                  {error.length > 0 && (
                    <>
                      <TableRow>
                        <TableCell colSpan={2}>Failed creation</TableCell>
                      </TableRow>
                      {error.map((error: any, key: number) => (
                        <TableRow key={`error_${key}`}>
                          <TableCell>{`${error.data.student_id} - ${error.data.student_name}`}</TableCell>
                          <TableCell>
                            <small>{error.message}</small>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center", pb: 3 }}>
              <Button
                onClick={onClosePreview}
                variant="outlined"
                color="success"
              >
                Close
              </Button>
            </DialogActions>
          </>
        );
        onSuccess(component);
      }

      dispatch({
        type: "SHOW",
        payload: res,
      });

      return await client.invalidateQueries("qr-codes").finally(() => onHide());
    },
    onError: (error: any) => {
      const { data: res } = error.response;

      dispatch({
        type: "SHOW",
        payload: res,
      });
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<QRCodeUploadSchemaType>({
    resolver: zodResolver(QRCodeUploadSchema),
  });

  function onSubmit() {
    const formData = new FormData(ref.current);

    mutate({
      data: formData,
      endpoint: "/qr-codes/upload",
    });
  }

  return (
    <Dialog open={show} onClose={onHide} fullWidth>
      <DialogTitle>Generate QR Code</DialogTitle>
      <Box
        width="100%"
        component="form"
        noValidate
        ref={ref}
        encType="multipart/form-data"
        onSubmit={handleSubmit(onSubmit)}
      >
        <DialogContent>
          <FormGroup>
            <TextField
              required={true}
              type="file"
              margin="dense"
              {...register("file")}
              autoFocus={true}
              error={errors.file ? true : false}
            />

            {errors.file?.message && (
              <Typography component="small" variant="caption" color="error">
                {errors.file.message.toString()}
              </Typography>
            )}
          </FormGroup>

          <FormGroup>
            <Autocomplete
              options={targetDocuments}
              renderInput={(params) => (
                <TextField
                  margin="dense"
                  required={true}
                  {...params}
                  label="Choose a target document"
                  {...register("purpose")}
                />
              )}
            />
          </FormGroup>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            color="inherit"
            type="reset"
            onClick={onHide}
          >
            Cancel
          </Button>

          <Button
            disabled={isLoading}
            variant="contained"
            color="success"
            type="submit"
          >
            {isLoading ? (
              <CircularProgress size={25} color="inherit" />
            ) : (
              "Generate"
            )}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
