import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { QRCode } from "../../schemas/qr_code";
import { ReactNode } from "react";

export default function ViewQrCode({
  show,
  onHide,
  code,
  children,
}: {
  show: boolean;
  onHide: () => void;
  code?: QRCode;
  children?: ReactNode;
}) {
  return (
    <Dialog open={show} onClose={onHide}>
      {children ?? (
        <>
          <DialogContent sx={{ p: 0 }}>
            <img src={code?.data_uri} alt={`code_${code?.id}`} />
          </DialogContent>

          <DialogActions sx={{ justifyContent: "center", pt: 0, pb: 3 }}>
            <Button
              download={`${code?.student_name.replaceAll(" ", "_") ?? ""}_${
                code?.student_id ?? ""
              }`.toLocaleLowerCase()}
              href={code?.data_uri ?? ""}
              variant="outlined"
              color="success"
            >
              Download
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
