import {
  Box,
  Button,
  CircularProgress,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoginSchema, LoginSchemaType } from "../../schemas/login";
import { useMutation } from "react-query";
import { APIResponse, POST } from "../../utils/api";
import { useMessaging } from "../../contexts/message_context";

function Login() {
  const { dispatch } = useMessaging();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginSchemaType>({
    resolver: zodResolver(LoginSchema),
  });
  const { mutate, isLoading } = useMutation(POST, {
    onSuccess: () => {
      window.location.href = "/codes";
    },
    onError: (error: any) => {
      const res: APIResponse = error?.response?.data;
      dispatch({
        type: "SHOW",
        payload: {
          is_success: false,
          message: res.message,
        },
      });
    },
  });

  function onSubmit(data: LoginSchemaType) {
    mutate({
      data,
      endpoint: "/admins/login",
    });
  }

  return (
    <>
      <Typography component="h1" variant="h5" fontWeight={500}>
        Sign in
      </Typography>

      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        width="100%"
        px={2}
      >
        <FormGroup>
          <TextField
            margin="normal"
            required={true}
            fullWidth={true}
            id="email"
            label="Email"
            autoComplete="email"
            autoFocus={true}
            error={errors.email ? true : false}
            {...register("email")}
          />

          {errors.email?.message && (
            <Typography variant="caption" color="red">
              {errors.email.message}
            </Typography>
          )}
        </FormGroup>

        <FormGroup>
          <TextField
            margin="normal"
            required={true}
            fullWidth={true}
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            error={errors.password ? true : false}
            {...register("password")}
          />

          {errors.password?.message && (
            <Typography variant="caption" color="red">
              {errors.password.message}
            </Typography>
          )}
        </FormGroup>
        <Button
          type="submit"
          size="large"
          fullWidth={true}
          variant="contained"
          color="info"
          disableElevation={true}
          sx={{ mt: 3, mb: 1 }}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress size={26} color="inherit" />
          ) : (
            <Typography fontWeight={500}>Sign In</Typography>
          )}
        </Button>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12}>
            <Typography align="center">
              Already an invitee?
              <Link to="/auth/sign-up">
                {" "}
                <strong>activate</strong>
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Login;
