import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  TextField,
  Typography,
} from "@mui/material";
import {
  QRCode,
  QRCodeDeleteSchema,
  QRCodeDeleteSchemaType,
} from "../../schemas/qr_code";
import { Info } from "@mui/icons-material";
import { useMutation, useQueryClient } from "react-query";
import { DELETE } from "../../utils/api";
import { useMessaging } from "../../contexts/message_context";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

export default function DeleteQrCode({
  show,
  onHide,
  code,
}: {
  show: boolean;
  onHide: () => void;
  code?: QRCode;
}) {
  const { dispatch } = useMessaging();
  const client = useQueryClient();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<QRCodeDeleteSchemaType>({
    resolver: zodResolver(QRCodeDeleteSchema),
  });

  const { mutate, isLoading } = useMutation(DELETE, {
    onSuccess: async (data) => {
      onHide();
      dispatch({
        type: "SHOW",
        payload: data.data,
      });

      return await client.invalidateQueries("qr-codes");
    },
    onError: (error: any) => {
      dispatch({
        type: "SHOW",
        payload: error.response.data,
      });
    },
  });

  function onDelete(data: QRCodeDeleteSchemaType) {
    mutate({
      endpoint: "/qr-codes",
      params: [
        {
          key: "id",
          value: code?.id,
        },
        {
          key: "reason",
          value: data.reason,
        },
      ],
    });
  }

  return (
    <Dialog open={show} onClose={onHide} fullWidth={true}>
      <DialogTitle>Delete Modal</DialogTitle>
      <Box component="form" noValidate={true} onSubmit={handleSubmit(onDelete)}>
        <DialogContent>
          <FormGroup>
            <TextField
              fullWidth={true}
              required={true}
              autoFocus={true}
              {...register("reason")}
              label="Why do you want to delete this QR Code?"
              error={errors.reason ? true : false}
            />

            {errors.reason?.message && (
              <Typography variant="caption" color="error">
                {errors.reason?.message}
              </Typography>
            )}
          </FormGroup>

          <Alert icon={<Info />} color="warning" sx={{ mt: 2 }}>
            Note that delete action cannot be undone.
          </Alert>
        </DialogContent>

        <DialogActions>
          <Button
            type="reset"
            onClick={onHide}
            variant="outlined"
            color="inherit"
          >
            Cancel
          </Button>

          <Button
            type="submit"
            disabled={isLoading}
            variant="contained"
            color="error"
          >
            {isLoading ? (
              <CircularProgress size={25} color="inherit" />
            ) : (
              "Delete"
            )}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
