import { ReactElement, createContext, useContext, useReducer } from "react";
import { Alert, Snackbar } from "@mui/material";

type MessageAction = {
  payload?: MessageData;
  type: "SHOW" | "HIDE";
};

type MessageData = {
  message: string;
  is_success: boolean;
  duration?: number;
};

type MessageState = MessageData & {
  show: boolean;
};

type Message = MessageState & {
  dispatch: (data: MessageAction) => any;
};

const defaultMessageData: MessageState = {
  is_success: false,
  message: "",
  show: false,
  duration: 6000,
};

const defaultMessage: Message = {
  ...defaultMessageData,
  dispatch: ({ type = "SHOW", payload = defaultMessageData }) => {},
};

const MessageContext = createContext(defaultMessage);

export default function MessageContextProvider({
  children,
}: {
  children: ReactElement;
}) {
  const [state, dispatch] = useReducer(messageReducer, defaultMessage);

  return (
    <MessageContext.Provider value={{ ...state, dispatch }}>
      {children}

      <Snackbar
        open={state.show}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={state.duration}
        onClose={() => dispatch({ type: "HIDE" })}
      >
        <Alert
          variant="filled"
          severity={state.is_success ? "success" : "error"}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </MessageContext.Provider>
  );
}

function messageReducer(state: MessageState, action: MessageAction) {
  switch (action.type) {
    case "SHOW":
      return {
        ...state,
        show: true,
        ...action.payload,
      };
    case "HIDE":
      return {
        ...state,
        show: false,
      };
    default:
      return state;
  }
}

export function useMessaging() {
  const context = useContext(MessageContext);

  if (!context)
    throw new Error(
      "useMessage only works inside of the MessageContextProvider"
    );

  return context;
}
