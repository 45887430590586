import {
  Button,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableProps,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { GET } from "../utils/api";
import { CSVLink } from "react-csv";
import { Data } from "react-csv/lib/core";
import { useQueryClient } from "react-query";

export default function DataGrid<T>({
  url,
  props,
  queryKey,
  columns,
  totals_url,
  renderRow,
  searchKeys,
  toolsComponent,
  handleDownload,
}: {
  url: string;
  totals_url: string;
  queryKey: string;
  props?: TableProps;
  searchKeys: string[];
  columns: { label: string; props?: {}; key?: string }[];
  renderRow: (data: T) => ReactNode;
  toolsComponent?: ReactNode;
  handleDownload?: (data: T[]) => void;
}) {
  const client = useQueryClient();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchVerb, setSearchVerb] = useState<string | null>("");

  const {
    data: res,
    isLoading,
    refetch,
    isFetching,
  } = GET({
    key: queryKey,
    endpoint: `${url}?page=${page + 1}&limit=${rowsPerPage}`,
  });

  const { data: total_res } = GET({
    key: "totals",
    endpoint: totals_url,
  });

  const data: T[] = useMemo(() => {
    if (!isLoading && res?.is_success) {
      return res?.data as T[];
    }

    return [] as T[];
  }, [res, isLoading]);

  const total: number = useMemo(() => {
    return total_res?.data?.total ?? 0;
  }, [total_res]);

  const tableData = useMemo(() => {
    return !searchVerb
      ? data
      : data.filter((d: any) => {
          let found = false;

          for (const key of searchKeys) {
            const value = d[key];
            if (value) {
              found =
                value.toLowerCase().indexOf(searchVerb.toLowerCase()) > -1;

              if (found) {
                break;
              }
            }
          }

          return found;
        });
  }, [data, searchVerb, searchKeys]);

  function search(event: any) {
    const { value } = event.target;
    if (value.length <= 0) return setSearchVerb(null);

    return setSearchVerb(value);
  }

  function handleChangeRowsPerPage(event: any) {
    setRowsPerPage(() => event.target.value);
  }

  function handleChangePage(e: React.MouseEvent | null, value: number) {
    setPage(() => value);
  }

  useEffect(() => {
    if (!isLoading) {
      refetch();
      client.invalidateQueries("totals");
    }
  }, [page, refetch, isLoading, rowsPerPage, client]);

  return (
    <>
      <Toolbar>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12} sm={8}>
            {toolsComponent}
          </Grid>
          <Grid item={true} flex="sm" xs={12} sm={4}>
            <TextField
              type="search"
              size="small"
              placeholder={`Search ${searchKeys
                ?.map((t) => t.replaceAll("_", " "))
                ?.join(",")}...`}
              onChange={search}
              fullWidth={true}
            />
          </Grid>
        </Grid>
      </Toolbar>

      <TableContainer component={Paper} sx={{ mt: 3, px: 3 }} elevation={0}>
        <Table sx={{ minWidth: 567 }} {...props}>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={`columns_${index}`} {...column.props}>
                  {column.label}
                </TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {tableData.length > 0 ? (
              tableData.map((value) => renderRow(value))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length + 1}>
                  <Typography align="center">
                    <em>No data found</em>{" "}
                    <Link to="" reloadDocument={true}>
                      reload
                    </Link>
                  </Typography>
                </TableCell>
              </TableRow>
            )}

            {(isLoading || isFetching) && (
              <TableRow>
                <TableCell colSpan={columns.length + 1}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        {tableData.length > 0 && (
          <Paper
            sx={{
              my: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              px: 2,
            }}
          >
            {handleDownload ? (
              <Button size="small" onClick={() => handleDownload(tableData)}>
                Download
              </Button>
            ) : (
              <CSVLink
                style={{
                  textDecoration: "none",
                  fontWeight: 500,
                }}
                target="_blank"
                rel="noreferrer"
                data={tableData as Data}
                headers={columns.map((col) => ({
                  label: col.label,
                  key: col.key ?? col.label.toLowerCase().replaceAll(" ", "_"),
                }))}
              >
                Download
              </CSVLink>
            )}

            {!isLoading && (
              <TablePagination
                page={page}
                count={total}
                color="success"
                component="div"
                showFirstButton={true}
                showLastButton={true}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[
                  {
                    value: 10,
                    label: "10",
                  },
                  {
                    value: 25,
                    label: "25",
                  },
                  {
                    value: 50,
                    label: "50",
                  },
                  {
                    value: 100,
                    label: "100",
                  },
                  {
                    value: 500,
                    label: "500",
                  },
                  {
                    value: total,
                    label: "All",
                  },
                ]}
              />
            )}
          </Paper>
        )}
      </TableContainer>
    </>
  );
}
