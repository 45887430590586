import { Backdrop, CircularProgress, Typography } from "@mui/material";

export default function Loading({
  open,
  caption,
}: {
  open: boolean;
  caption?: string;
}) {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <CircularProgress color="inherit" sx={{ mr: 2 }} />
      {caption && (
        <Typography variant="button" color="inherit">
          {caption}
        </Typography>
      )}
    </Backdrop>
  );
}
