import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Admin } from "../../schemas/admin";

export default function ViewAdmin({
  show,
  onHide,
  admin,
}: {
  show: boolean;
  admin: Admin | null;
  onHide: () => void;
}) {
  return (
    <Dialog open={show} onClose={onHide} fullWidth>
      <DialogTitle>Admin Details</DialogTitle>

      <DialogContent>
        <TextField
          InputProps={{
            readOnly: true,
          }}
          value={`${admin?.last_name} ${admin?.first_name} ${
            admin?.other_name ?? ""
          }`.trim()}
          placeholder="Full Name"
          label="Full Name"
          fullWidth={true}
          variant="standard"
        />

        <TextField
          InputProps={{
            readOnly: true,
          }}
          value={admin?.email}
          placeholder="Email"
          label="Email"
          fullWidth={true}
          variant="standard"
        />
      </DialogContent>

      <DialogActions></DialogActions>
    </Dialog>
  );
}
