import { z } from "zod";

export const SignUpSchema = z
  .object({
    first_name: z
      .string({ required_error: "First name is required" })
      .min(1, "First name is required"),
    last_name: z
      .string({ required_error: "Last name is required" })
      .min(1, "Last name is required"),
    other_name: z.string().optional(),
    designation: z
      .string({ required_error: "Last name is required" })
      .min(1, "Last name is required"),
    email: z
      .string({ required_error: "Email address is required" })
      .min(1, "Email address is required")
      .email({ message: "Invalid email address" }),
    password: z
      .string({ required_error: "Password is required" })
      .min(1, "Password is required"),
    confirm_password: z
      .string({ required_error: "Confirm password is required" })
      .min(1, "Confirm password is required"),
  })
  .refine(
    (data) => {
      return data.password === data.confirm_password;
    },
    { message: "Passwords do not match", path: ["confirm_password"] }
  );

export type SignUpSchemaType = z.infer<typeof SignUpSchema>;
