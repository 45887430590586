import {
  Box,
  Button,
  CircularProgress,
  FormGroup,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { SignUpSchema, SignUpSchemaType } from "../../schemas/sign_up";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "react-query";
import { APIResponse, POST } from "../../utils/api";
import { useMessaging } from "../../contexts/message_context";

function SignUp() {
  const navigate = useNavigate();
  const { dispatch } = useMessaging();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpSchemaType>({
    resolver: zodResolver(SignUpSchema),
  });
  const { mutate, isLoading } = useMutation(POST, {
    onSuccess: (data) => {
      dispatch({
        type: "SHOW",
        payload: data.data,
      });
      return navigate("/auth/login", { replace: true });
    },
    onError: (error: any) => {
      const res: APIResponse = error?.response?.data;
      dispatch({
        type: "SHOW",
        payload: {
          is_success: false,
          message: res.message,
        },
      });
    },
  });

  function onSubmit(posted: SignUpSchemaType) {
    const { email, ...data } = posted;

    mutate({
      data,
      endpoint: `/admins?email=${email}`,
    });
  }

  return (
    <>
      <Typography component="h1" variant="h5" fontWeight={500}>
        Activate Account
      </Typography>

      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        width="100%"
        px={2}
      >
        <FormGroup>
          <TextField
            margin="dense"
            required={true}
            fullWidth={true}
            id="first_name"
            label="First Name"
            autoComplete="name"
            autoFocus={true}
            error={errors.first_name ? true : false}
            {...register("first_name")}
          />

          {errors.first_name?.message && (
            <Typography variant="caption" color="red">
              {errors.first_name.message}
            </Typography>
          )}
        </FormGroup>

        <FormGroup>
          <TextField
            margin="dense"
            required={true}
            fullWidth={true}
            id="last_name"
            label="Last Name"
            autoComplete="name"
            error={errors.last_name ? true : false}
            {...register("last_name")}
          />

          {errors.last_name?.message && (
            <Typography variant="caption" color="red">
              {errors.last_name.message}
            </Typography>
          )}
        </FormGroup>

        <FormGroup>
          <TextField
            margin="dense"
            fullWidth={true}
            id="other_name"
            label="Other Name(s)"
            autoComplete="name"
            error={errors.other_name ? true : false}
            {...register("other_name")}
          />

          {errors.other_name?.message && (
            <Typography variant="caption" color="red">
              {errors.other_name.message}
            </Typography>
          )}
        </FormGroup>

        <FormGroup>
          <TextField
            margin="dense"
            required={true}
            fullWidth={true}
            id="designation"
            label="Designation"
            autoComplete="designation"
            error={errors.designation ? true : false}
            {...register("designation")}
          />

          {errors.designation?.message && (
            <Typography variant="caption" color="red">
              {errors.designation.message}
            </Typography>
          )}
        </FormGroup>

        <FormGroup>
          <TextField
            margin="dense"
            required={true}
            fullWidth={true}
            id="email"
            label="Email"
            autoComplete="email"
            error={errors.email ? true : false}
            {...register("email")}
          />

          {errors.email?.message && (
            <Typography variant="caption" color="red">
              {errors.email.message}
            </Typography>
          )}
        </FormGroup>

        <FormGroup>
          <TextField
            margin="dense"
            required={true}
            fullWidth={true}
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            error={errors.password ? true : false}
            {...register("password")}
          />

          {errors.password?.message && (
            <Typography variant="caption" color="red">
              {errors.password.message}
            </Typography>
          )}
        </FormGroup>

        <FormGroup>
          <TextField
            margin="dense"
            required={true}
            fullWidth={true}
            label="Confirm Password"
            type="password"
            id="confirm-password"
            autoComplete="current-password"
            error={errors.confirm_password ? true : false}
            {...register("confirm_password")}
          />

          {errors.confirm_password?.message && (
            <Typography variant="caption" color="red">
              {errors.confirm_password.message}
            </Typography>
          )}
        </FormGroup>
        <Button
          type="submit"
          fullWidth={true}
          variant="contained"
          color="success"
          size="large"
          disableElevation={true}
          sx={{ mt: 3, mb: 1 }}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress size={26} color="inherit" />
          ) : (
            <Typography fontWeight={500}>Activate</Typography>
          )}
        </Button>

        <Typography align="center">
          Already activated?
          <Link to="/auth/login">
            {" "}
            <strong>login</strong>
          </Link>
        </Typography>
      </Box>
    </>
  );
}

export default SignUp;
