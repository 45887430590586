import { ReactNode, useState } from "react";
import Header from "../Header";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
} from "@mui/material";
import {
  Add,
  Delete,
  Download,
  Refresh,
  Visibility,
} from "@mui/icons-material";
import Generate from "../qr_code/Generate";
import DataGrid from "../DataGrid";
import { QRCode } from "../../schemas/qr_code";
import ViewQrCode from "../qr_code/ViewQrCode";
import DeleteQrCode from "../qr_code/DeleteQrCode";
import { useAuth } from "../../contexts/auth_context";
import Upload from "../qr_code/Upload";
import useZip from "../../hooks/zip";
import { getQRCodeFileName } from "../../utils/utils";
import { useQueryClient } from "react-query";

function QRCodes() {
  const {
    data: { role },
  } = useAuth();
  const client = useQueryClient();
  const { handleZipBase64Images } = useZip();
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchor);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [showGenerateDialog, setShowGenerateDialog] = useState(false);
  const [action, setAction] = useState<{
    code?: QRCode;
    type: "delete" | "view";
    component?: ReactNode;
  }>();

  async function handleBatchDownload(data: QRCode[]) {
    const contents = await handleZipBase64Images(
      data.map((d) => ({
        isBase64: true,
        name: getQRCodeFileName(d, true),
        data: d.data_uri.replace("data:image/png;base64,", ""),
      }))
    );

    const url = URL.createObjectURL(contents);

    window.open(url, "_blank");
  }

  async function refetch() {
    await client.invalidateQueries(["qr-codes"]);
  }

  return (
    <>
      <Header title="Manage QR Codes" />

      <DataGrid<QRCode>
        queryKey="qr-codes"
        url={`/qr-codes/${role === "admin" ? "created" : "all"}`}
        totals_url={`/qr-codes/total-${role === "admin" ? "created" : "all"}`}
        props={{
          size: "small",
        }}
        columns={[
          {
            label: "QR",
          },
          {
            label: "Student Name",
          },
          {
            label: "Student ID",
          },
          {
            label: "Document",
          },
          {
            label: "Generated By",
          },
        ]}
        renderRow={(code) => (
          <TableRow key={code.id}>
            <TableCell>
              <Button
                sx={{
                  p: ".06rem",
                  minWidth: "fit-content",
                  border: ".1rem solid gray",
                }}
                onClick={() => setAction({ code, type: "view" })}
              >
                <img src={code.data_uri} alt={`code_${code.id}`} width={35} />
              </Button>
            </TableCell>
            <TableCell>{code.student_name}</TableCell>
            <TableCell>{code.student_id}</TableCell>
            <TableCell>{code.purpose}</TableCell>
            <TableCell>{code.generated_by}</TableCell>
            <TableCell>
              <Stack direction="row" justifyContent="right" spacing={1}>
                <IconButton
                  onClick={() => setAction({ code, type: "view" })}
                  size="small"
                  color="info"
                >
                  <Visibility />
                </IconButton>
                <IconButton
                  download={`${code.student_name.replaceAll(" ", "_")}_${
                    code.student_id
                  }`.toLocaleLowerCase()}
                  href={code.data_uri}
                  size="small"
                  color="success"
                >
                  <Download />
                </IconButton>

                <IconButton
                  onClick={() => setAction({ code, type: "delete" })}
                  size="small"
                  color="error"
                >
                  <Delete />
                </IconButton>
              </Stack>
            </TableCell>
          </TableRow>
        )}
        searchKeys={["student_name", "purpose", "student_id", "generated_by"]}
        toolsComponent={
          <Stack spacing={1} direction="row">
            <div>
              <Button
                variant="outlined"
                size="small"
                color="success"
                onClick={({ currentTarget }) => setAnchor(currentTarget)}
                sx={{}}
                aria-controls={menuOpen ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={menuOpen ? "true" : undefined}
              >
                <Add />
                <span>Generate Code</span>
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchor}
                open={menuOpen}
                onClose={() => setAnchor(null)}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    setAnchor(null);
                    setShowGenerateDialog(true);
                  }}
                >
                  Single Generate
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setAnchor(null);
                    setShowUploadDialog(true);
                  }}
                >
                  Batch Upload
                </MenuItem>
              </Menu>
            </div>

            <Button
              variant="outlined"
              size="small"
              color="info"
              onClick={() => refetch()}
              sx={{}}
            >
              <Refresh />
              <span>Refresh</span>
            </Button>
          </Stack>
        }
        handleDownload={(data) => handleBatchDownload(data)}
      />

      <Generate
        show={showGenerateDialog}
        onHide={() => setShowGenerateDialog((pre) => !pre)}
        onSuccess={(data) => setAction({ code: data, type: "view" })}
      />

      <Upload
        show={showUploadDialog}
        handleDownload={handleBatchDownload}
        onHide={() => setShowUploadDialog((pre) => !pre)}
        onSuccess={(data) => setAction({ component: data, type: "view" })}
        onClosePreview={() => setAction(undefined)}
      />

      <ViewQrCode
        show={action?.type === "view"}
        onHide={() => setAction(undefined)}
        code={action?.code}
        children={action?.component}
      />

      <DeleteQrCode
        show={action?.type === "delete"}
        code={action?.code}
        onHide={() => setAction(undefined)}
      />
    </>
  );
}

export default QRCodes;
