import Header from "../Header";

function Index() {
  return (
    <>
      <Header title="Dashboard" />
    </>
  );
}

export default Index;
