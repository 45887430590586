import { useState } from "react";
import Header from "../Header";
import {
  Avatar,
  Button,
  Chip,
  Stack,
  TableCell,
  TableRow,
} from "@mui/material";
import { Add, Refresh } from "@mui/icons-material";
import InviteAdmin from "../manage_admins/InviteAdmin";
import { Admin } from "../../schemas/admin";
import DataGrid from "../DataGrid";
import ViewAdmin from "../manage_admins/ViewAdmin";
import { capitalise } from "../../utils/utils";
import { useQueryClient } from "react-query";

function Admins() {
  const client = useQueryClient();
  const [selected, setSelected] = useState<Admin | null>(null);
  const [showInviteDialog, setShowInviteDialog] = useState(false);

  async function refetch() {
    await client.invalidateQueries("admins");
  }

  return (
    <>
      <Header title="Manage Admins" />

      <DataGrid<Admin>
        url="/admins/all"
        queryKey="admins"
        totals_url="/admins/total-all"
        columns={[
          {
            label: "Photo",
          },
          {
            label: "Full Name",
          },
          {
            label: "Email",
          },
          {
            label: "Designation",
          },
          {
            label: "Role",
          },
          {
            label: "Status",
            props: { align: "center" },
          },
        ]}
        renderRow={(admin) => (
          <TableRow key={admin.email}>
            <TableCell>
              {admin.image_url || admin.status === "invited" ? (
                <Avatar sx={{ width: 30, height: 30 }} />
              ) : (
                <Avatar sx={{ width: 30, height: 30 }}>
                  {admin.first_name.charAt(0)}
                </Avatar>
              )}
            </TableCell>
            <TableCell>
              {`${admin.last_name} ${admin.first_name} ${
                admin.other_name ?? ""
              }`.trim()}
            </TableCell>
            <TableCell>{admin.email}</TableCell>
            <TableCell>{admin.designation}</TableCell>
            <TableCell>{capitalise({ str: admin.role })}</TableCell>
            <TableCell align="center">
              <Chip
                size="small"
                component="small"
                label={admin.status.toLocaleUpperCase()}
                color={admin.is_active ? "success" : "error"}
              />
            </TableCell>
            <TableCell align="right">
              <Button
                size="small"
                color="info"
                onClick={() => setSelected(admin)}
              >
                View
              </Button>
            </TableCell>
          </TableRow>
        )}
        searchKeys={["first_name", "last_name", "other_name", "email"]}
        toolsComponent={
          <Stack spacing={1} direction="row">
            <Button
              variant="outlined"
              size="small"
              color="success"
              onClick={() => setShowInviteDialog(true)}
              sx={{}}
            >
              <Add />
              <span>Invite New Admin</span>
            </Button>

            <Button
              variant="outlined"
              size="small"
              color="info"
              onClick={() => refetch()}
              sx={{}}
            >
              <Refresh />
              <span>Refresh</span>
            </Button>
          </Stack>
        }
      />

      <InviteAdmin
        show={showInviteDialog}
        onHide={() => setShowInviteDialog((pre) => !pre)}
      />

      <ViewAdmin
        admin={selected}
        show={selected !== null}
        onHide={() => setSelected(null)}
      />
    </>
  );
}

export default Admins;
