import { Toolbar, Typography } from "@mui/material";

export default function Header({
  title,
  children,
}: {
  title: string;
  children?: any;
}) {
  return (
    <Toolbar variant="dense">
      <Typography
        variant="button"
        fontSize="1.2rem"
        fontWeight={700}
        sx={{ flexGrow: 1 }}
      >
        {title}
      </Typography>

      <div>{children}</div>
    </Toolbar>
  );
}
