import { ArrowDropDown } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import NavMenu from "./NavMenu";
import { useAuth } from "../../contexts/auth_context";
import { useMutation } from "react-query";
import { POST } from "../../utils/api";

export default function Main() {
  const navigate = useNavigate();
  const [showOpenDrawer, setShowOpenDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isAccountMenuOpen = Boolean(anchorEl);
  const { data, is_authenticated } = useAuth();
  const { mutate } = useMutation(POST, {
    onSuccess: () => {
      handleNavigate("/auth/login");
    },
  });

  function handleNavigate(href: string) {
    if (href.length <= 0) href = "/";

    handleHideAccountMenu();

    return navigate(href);
  }

  const handleShowAccountMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleHideAccountMenu = () => {
    setAnchorEl(null);
  };

  return !is_authenticated ? (
    <Navigate to="/auth/login" replace={true} />
  ) : (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar
          position="fixed"
          elevation={0}
          color="primary"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar variant="dense">
            <IconButton
              size="small"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setShowOpenDrawer((pre) => !pre)}
              sx={{ mr: 1, display: { xs: "inline", sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <img src="/frame_logo.png" alt="frame" width={30} />
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, ml: 1 }}
            >
              QR Generator
            </Typography>
            <div>
              <Button
                size="small"
                color="inherit"
                variant="outlined"
                id="account-button"
                aria-haspopup="true"
                disableElevation={true}
                onClick={handleShowAccountMenu}
                aria-label="account of current user"
                aria-controls={isAccountMenuOpen ? "account-menu" : undefined}
              >
                <Avatar sx={{ width: 22, height: 22, mr: 1 }}>
                  {data.first_name.charAt(0)}
                </Avatar>
                <Typography variant="button" fontWeight={600}>
                  <small>{data.role}</small>
                </Typography>
                <ArrowDropDown />
              </Button>

              <Menu
                id="account-menu"
                anchorEl={anchorEl}
                open={isAccountMenuOpen}
                onClose={handleHideAccountMenu}
                MenuListProps={{ "aria-labelledby": "account-button" }}
                slotProps={{
                  paper: {
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&::before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <Box
                  sx={{
                    px: 2,
                    py: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Avatar>{`${data.last_name.charAt(0)}${data.first_name.charAt(
                    0
                  )}`}</Avatar>

                  <div>
                    <Typography
                      variant="overline"
                      component="p"
                      fontWeight={500}
                      lineHeight={1}
                    >
                      {`${data.last_name} ${data.first_name} ${
                        data.other_name ?? ""
                      }`.trim()}
                    </Typography>
                    <Typography
                      variant="caption"
                      component="small"
                      color="GrayText"
                      lineHeight={1}
                    >
                      {data.email}
                    </Typography>
                  </div>
                </Box>

                <Divider />

                <MenuItem onClick={() => handleNavigate("/profile")}>
                  Profile
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    mutate({ endpoint: "/admins/logout", data: {} })
                  }
                >
                  Logout
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>

        <NavMenu
          show={showOpenDrawer}
          onHide={() => setShowOpenDrawer(false)}
        />

        <Box component="main" sx={{ flexGrow: 1, py: 2 }}>
          <Toolbar variant="dense" />

          <Outlet />
        </Box>
      </Box>
    </>
  );
}
