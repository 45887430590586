import JSZip from "jszip";

export type zipDataType = {
  name: string;
  data: any;
};

export type zipImageType = zipDataType & {
  data: string;
  isBase64: true;
};

export default function useZip() {
  async function handleZipBase64Images(files: zipImageType[]) {
    const zip = new JSZip();
    files.forEach((file) => {
      zip.file(`${file.name}.png`, file.data, { base64: file.isBase64 });
    });

    return await zip.generateAsync({ type: "blob" });
  }

  return {
    handleZipBase64Images,
  };
}
