import { QrCode, People, Dashboard, Info } from "@mui/icons-material";
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/auth_context";

export default function NavMenu({
  show,
  onHide,
}: {
  show: boolean;
  onHide: () => void;
}) {
  const drawerWidth = 240;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  function isCurrent(path: string, exact: boolean = true): boolean {
    return exact ? path === pathname : pathname.indexOf(path) > -1;
  }

  function handleNavigate(href: string, shouldHide: boolean) {
    if (href.length <= 0) href = "/";

    if (shouldHide) onHide();

    return navigate(href);
  }

  function MenuContent({ shouldHide }: { shouldHide: boolean }) {
    const {
      data: { role },
    } = useAuth();
    return (
      <>
        <Toolbar />

        <List>
          {[
            {
              name: "Dashboard",
              path: "/",
              slug: "dashboard",
              icon: <Dashboard />,
              roles: ["developer"],
            },
            {
              name: "Manage Admins",
              path: "/admins",
              slug: "admins",
              icon: <People />,
              roles: ["developer", "super-admin"],
            },
            {
              name: "Manage Codes",
              path: "/codes",
              slug: "qr-codes",
              icon: <QrCode />,
              roles: ["developer", "super-admin", "admin"],
            },
            {
              name: "System Logs",
              path: "/logs",
              slug: "logs",
              icon: <Info />,
              roles: ["developer", "super-admin"],
            },
          ]
            .filter((path) =>
              Boolean(
                path.roles.find(
                  (r) => r.toLocaleLowerCase() === role.toLocaleLowerCase()
                )
              )
            )
            .map((route) => (
              <ListItem key={route.slug} disablePadding={true}>
                <ListItemButton
                  selected={isCurrent(route.path)}
                  onClick={() => handleNavigate(route.path, shouldHide)}
                >
                  <ListItemIcon>{route.icon}</ListItemIcon>
                  <ListItemText primary={route.name} />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </>
    );
  }

  return (
    <>
      <Drawer
        variant="temporary"
        open={show}
        onClose={onHide}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
        anchor="left"
      >
        <MenuContent shouldHide={true} />
      </Drawer>

      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
        open={true}
        anchor="left"
      >
        <MenuContent shouldHide={false} />
      </Drawer>
    </>
  );
}
