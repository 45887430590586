import axios, { AxiosInstance } from "axios";
import { useQuery } from "react-query";

export type APIResponse = {
  is_failure: boolean;
  is_success: boolean;
  message: string;
  data: any | object | object[] | null;
};

export const baseURL: string =
  process.env.NODE_ENV === "development" ? "" : "/api/qr-generator";

const api: AxiosInstance = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
  },
});

async function get(endpoint: string): Promise<APIResponse> {
  return await api
    .get(endpoint)
    .then((res): APIResponse => res.data)
    .catch((err): APIResponse => err?.response?.data);
}

export function GET({ key, endpoint }: { key: string; endpoint: string }) {
  const doGet = () => get(endpoint);
  return useQuery(key, doGet, {
    staleTime: 60,
    refetchOnWindowFocus: false,
  });
}

export function POST({ endpoint, data }: { endpoint: string; data: object }) {
  return api.post(endpoint, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function DELETE({
  endpoint,
  params,
}: {
  endpoint: string;
  params: { key: string; value: any }[];
}) {
  const query = params.map((param) => `${param.key}=${param.value}`);

  if (query.length > 0) endpoint += `?${query.join("&")}`;

  return api.delete(endpoint);
}

export function UPLOAD({
  endpoint,
  data,
}: {
  endpoint: string;
  data: FormData;
}) {
  return api.post(endpoint, data);
}
