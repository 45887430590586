import { z } from "zod";

export const QRCodeSchema = z.object({
  student_id: z
    .string({ required_error: "Student ID is required" })
    .min(1, "Student ID is required"),
  student_name: z
    .string({ required_error: "Student full name is required" })
    .min(1, "Student full name is required"),
  purpose: z
    .string({ required_error: "Target document is required" })
    .min(1, "Target document is required"),
});

export type QRCodeSchemaType = z.infer<typeof QRCodeSchema>;

export const QRCodeUploadSchema = z.object({
  file: z
    .any()
    .refine((file) => file?.length === 1, "File field is required")
    .refine((file) => file[0]?.type === "text/csv", "File must be a CSV."),
  purpose: z
    .string({ required_error: "Target document is required" })
    .min(1, "Target document is required"),
});

export type QRCodeUploadSchemaType = z.infer<typeof QRCodeUploadSchema>;

export type QRCode = {
  id: number;
  student_id: string;
  student_name: string;
  data_uri: string;
  template: string;
  generated_at: string;
  generated_by: string;
  purpose: string;
};

export const QRCodeDeleteSchema = z.object({
  reason: z
    .string({ required_error: "Please provide deletion reason" })
    .min(1, "Please provide deletion reason"),
});

export type QRCodeDeleteSchemaType = z.infer<typeof QRCodeDeleteSchema>;
