import Header from "../Header";
import { Button, Stack, TableCell, TableRow } from "@mui/material";
import { Refresh } from "@mui/icons-material";
import DataGrid from "../DataGrid";
import { useQueryClient } from "react-query";
import { Log } from "../../schemas/log";

function Logs() {
  const client = useQueryClient();

  async function refetch() {
    await client.invalidateQueries("logs");
  }

  return (
    <>
      <Header title="System Logs" />

      <DataGrid<Log>
        queryKey="logs"
        url={`/logs/all`}
        totals_url={`/logs/total`}
        columns={[
          {
            label: "Triggered On",
            key: "created_at",
          },
          {
            label: "Triggered By",
          },
          {
            label: "Action",
          },
          {
            label: "IPv4",
            key: "ipv4_address",
          },
          {
            label: "Device",

            key: "client_device",
          },
          {
            label: "Status",
          },
          {
            label: "Details",
            key: "message",
          },
        ]}
        renderRow={(log) => (
          <TableRow key={log.id}>
            <TableCell>{log.created_at}</TableCell>
            <TableCell>{log.triggered_by}</TableCell>
            <TableCell>{log.action}</TableCell>
            <TableCell>{log.ipv4_address}</TableCell>
            <TableCell>{log.client_device}</TableCell>
            <TableCell>{log.status}</TableCell>
            <TableCell>{log.message}</TableCell>
          </TableRow>
        )}
        searchKeys={["triggered_by", "action", "status"]}
        toolsComponent={
          <Stack spacing={1} direction="row">
            <Button
              variant="outlined"
              size="small"
              color="info"
              onClick={() => refetch()}
              sx={{}}
            >
              <Refresh />
              <span>Refresh</span>
            </Button>
          </Stack>
        }
      />

      {/* <DeleteQrLog
        show={action?.type === "delete"}
        code={action?.code}
        onHide={() => setAction(undefined)}
      /> */}
    </>
  );
}

export default Logs;
