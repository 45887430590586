import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { APIResponse, POST } from "../../utils/api";
import { useMessaging } from "../../contexts/message_context";

export default function InviteAdmin({
  show,
  onHide,
}: {
  show: boolean;
  onHide: () => void;
}) {
  const client = useQueryClient();
  const { dispatch } = useMessaging();
  const { mutate, isLoading } = useMutation(POST, {
    onSuccess: async (res) => {
      const { message } = res.data;

      dispatch({
        type: "SHOW",
        payload: { message, is_success: true },
      });

      onHide();

      return await client.invalidateQueries("admins");
    },
    onError: (error: any) => {
      const res: APIResponse = error?.response?.data;
      dispatch({
        type: "SHOW",
        payload: {
          is_success: false,
          message: res.message,
        },
      });
    },
  });

  function handleInvite(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }

    const data = Object.fromEntries(new FormData(event.currentTarget));

    mutate({
      data,
      endpoint: "/admins/invite",
    });
  }

  return (
    <Dialog
      open={show}
      onClose={onHide}
      fullWidth
      PaperProps={{
        component: "form",
        onSubmit: handleInvite,
      }}
    >
      <DialogTitle>Invite New Admin</DialogTitle>

      <DialogContent>
        {/* <DialogContentText>Data</DialogContentText> */}

        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12} sm={7}>
            <TextField
              autoFocus={true}
              required={true}
              margin="dense"
              id="name"
              name="email"
              label="Email Address"
              type="email"
              fullWidth={true}
            />
          </Grid>

          <Grid item={true} xs={12} sm={5}>
            <Autocomplete
              id="role"
              options={[
                {
                  label: "Admin",
                  value: "admin",
                },
                {
                  label: "Super Admin",
                  value: "super-admin",
                },
              ]}
              renderInput={(params) => (
                <TextField
                  margin="dense"
                  name="role"
                  {...params}
                  label="Choose a role"
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          type="reset"
          onClick={onHide}
          variant="outlined"
          color="inherit"
        >
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          type="submit"
          variant="contained"
          color="success"
        >
          {isLoading ? (
            <CircularProgress size={25} color="inherit" />
          ) : (
            "Invite"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
