import { QRCode } from "../schemas/qr_code";

export function capitalise({
  str,
  separator,
}: {
  str: string;
  separator?: string;
}) {
  separator ??= "-";
  return str
    .split(separator)
    .map((s) => `${s.charAt(0).toLocaleUpperCase()}${s.slice(1)}`)
    .join(" ");
}

export const targetDocuments = [
  "Transcript",
  "Certificate",
  "Statement of Result",
  "Attestation",
  "Examination Pass",
];

export function getQRCodeFileName(
  code: QRCode,
  includePurpose: boolean = false
) {
  let name = `${code.student_id}`;

  if (includePurpose) {
    name += "_" + code.purpose.replaceAll(" ", "_");
  }

  return name.toLowerCase();
}
