import { z } from "zod";

export const LoginSchema = z.object({
  email: z
    .string({ required_error: "Email address is required" })
    .min(1, "Email address is required"),
  password: z
    .string({ required_error: "Password is required" })
    .min(1, "Password is required"),
});

export type LoginSchemaType = z.infer<typeof LoginSchema>;
