import {
  Context,
  ReactElement,
  createContext,
  useContext,
  useMemo,
} from "react";
import { GET } from "../utils/api";
import Loading from "../components/Loading";

type AuthAdmin = {
  first_name: string;
  last_name: string;
  other_name: string | null;
  email: string;
  role: string;
  designation: string;
  is_active: boolean;
  otp: string | null;
  image_url: string;
  status: "active" | "pending" | "disabled" | "suspended";
};

type AdminContext = {
  data: AuthAdmin;
  is_authenticating: boolean;
  is_authenticated: boolean;
};

const defaultAdmin: AdminContext = {
  data: {
    first_name: "",
    designation: "",
    email: "",
    image_url: "",
    is_active: false,
    last_name: "",
    other_name: null,
    otp: "",
    role: "",
    status: "pending",
  },
  is_authenticated: false,
  is_authenticating: true,
};

const AuthContext: Context<AdminContext> = createContext(defaultAdmin);

export default function AuthContextProvider({
  children,
}: {
  children: ReactElement;
}): JSX.Element {
  const { data, isLoading } = GET({ key: "auth", endpoint: "/admins/session" });

  const authAdmin: AdminContext = useMemo(() => {
    if (!isLoading) {
      if (data?.is_success) {
        defaultAdmin.data = data.data as AuthAdmin;
      }
    }

    return {
      ...defaultAdmin,
      is_authenticating: isLoading,
      is_authenticated: data?.is_success ?? false,
    };
  }, [isLoading, data]);

  return isLoading || !Boolean(data) ? (
    <Loading open={isLoading} caption="Authenticating..." />
  ) : (
    <AuthContext.Provider value={{ ...authAdmin }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context)
    throw new Error("useAuth hook only works in the AuthContextProvider");

  return context;
}
