import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { QRCode, QRCodeSchema, QRCodeSchemaType } from "../../schemas/qr_code";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "react-query";
import { POST } from "../../utils/api";
import { useMessaging } from "../../contexts/message_context";
import { targetDocuments } from "../../utils/utils";

export default function Generate({
  show,
  onHide,
  onSuccess,
}: {
  show: boolean;
  onHide: () => void;
  onSuccess: (qr_code: QRCode) => void;
}) {
  const client = useQueryClient();
  const { dispatch } = useMessaging();
  const { mutate, isLoading } = useMutation(POST, {
    onSuccess: async (data) => {
      const { data: res } = data;

      if (res.is_success) onSuccess(res.data);

      dispatch({
        type: "SHOW",
        payload: res,
      });

      return await client.invalidateQueries("qr-codes").finally(() => onHide());
    },
    onError: (error: any) => {
      const { data: res } = error.response;

      dispatch({
        type: "SHOW",
        payload: res,
      });
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<QRCodeSchemaType>({
    resolver: zodResolver(QRCodeSchema),
  });

  function onSubmit(data: any) {
    mutate({
      data,
      endpoint: "/qr-codes",
    });
  }

  return (
    <Dialog open={show} onClose={onHide} fullWidth>
      <DialogTitle>Generate QR Code</DialogTitle>
      <Box
        width="100%"
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <DialogContent>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} sm={3}>
              <FormGroup>
                <TextField
                  required={true}
                  margin="dense"
                  label="Student ID"
                  {...register("student_id")}
                  autoFocus={true}
                  error={errors.student_id ? true : false}
                />

                {errors.student_id?.message && (
                  <Typography component="small" variant="caption" color="error">
                    {errors.student_id.message}
                  </Typography>
                )}
              </FormGroup>
            </Grid>

            <Grid item={true} xs={12} sm={9}>
              <FormGroup>
                <TextField
                  required={true}
                  margin="dense"
                  label="Student full name"
                  {...register("student_name")}
                  error={errors.student_name ? true : false}
                />

                {errors.student_name?.message && (
                  <Typography component="small" variant="caption" color="error">
                    {errors.student_name.message}
                  </Typography>
                )}
              </FormGroup>
            </Grid>
          </Grid>

          <FormGroup>
            <Autocomplete
              options={targetDocuments}
              renderInput={(params) => (
                <TextField
                  margin="dense"
                  required={true}
                  {...params}
                  label="Choose a target document"
                  {...register("purpose")}
                />
              )}
            />
          </FormGroup>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            color="inherit"
            type="reset"
            onClick={onHide}
          >
            Cancel
          </Button>

          <Button
            disabled={isLoading}
            variant="contained"
            color="success"
            type="submit"
          >
            {isLoading ? (
              <CircularProgress size={25} color="inherit" />
            ) : (
              "Generate"
            )}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
