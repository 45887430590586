import { Box, Container } from "@mui/material";
import { Outlet } from "react-router-dom";

export default function Auth() {
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          py: { xs: 8, sm: 2 },
          minHeight: { sm: "100vh" },
          justifyContent: { sm: "center" },
        }}
      >
        <img src="/frame_logo.png" alt="frame_logo" width={64} />

        <Outlet />
      </Box>
    </Container>
  );
}
