import { Route, Routes } from "react-router-dom";
import Index from "./components/pages/Index";
import Main from "./components/layouts/Main";
import Admins from "./components/pages/Admins";
import QRCodes from "./components/pages/QRCodes";
import SignUp from "./components/pages/SignUp";
import Login from "./components/pages/Login";
import Auth from "./components/layouts/Auth";
import Authenticate from "./components/pages/Authenticate";
import Logs from "./components/pages/Logs";

function App() {
  return (
    <Routes>
      <Route path="/auth" element={<Auth />}>
        <Route index element={<Authenticate />} />
        <Route path="login" element={<Login />} />
        <Route path="sign-up" element={<SignUp />} />
      </Route>

      <Route path="/" element={<Main />}>
        <Route index element={<Index />} />
        <Route path="admins" element={<Admins />} />
        <Route path="admins" element={<Admins />} />
        <Route path="codes" element={<QRCodes />} />
        <Route path="logs" element={<Logs />} />
        <Route path="*" element={<>Not found</>} />
      </Route>
    </Routes>
  );
}

export default App;
